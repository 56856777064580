import './Settings.less';
import ImprTab from '../../../components/tab/ImprTab';

const Settings = () => {
  const tabItems = [
    {
      key: 'integrations',
      label: 'INTEGRATIONS',
      children: <div>Content of Integrations</div>,
    },
    {
      key: 'directories',
      label: 'DIRECTORIES',
      children: <div>Content of Directories</div>,
    },
    {
      key: 'groups',
      label: 'GROUPS',
      children: <div>Content of Groups</div>,
    },
    {
      key: 'customize',
      label: 'CUSTOMIZE',
      children: <div>Content of Customize</div>,
    },
  ];
  return (
    <div className={'settings-container'}>
      <div className={'settings-header'}>
        <h1>Settings</h1>
      </div>
      <div className={'settings-tab-container'}>
        <div className={'settings-tab-header'}>
          <ImprTab tabItems={tabItems} type={'card'} size={'large'} data-testid={'settings-tabs'} />
        </div>
      </div>
    </div>
  );
};

export default Settings;
