// Copyright 2024, Imprivata, Inc.  All rights reserved.

export const Endpoints = {
  START_TENANT_SETUP: '/am-portal-setup-svc/tenant-setup/v1/start',
  DPA_CHECK: '/am-portal-setup-svc/user-consent/v2/consent/dpa-check',
  SETUP_CONNECT_CLIENT_CREATE: '/am-portal-setup-svc/connect-eamc/v1/client/create',
  PORTAL_CONNECT_CLIENT_CREATE: '/am-portal-svc/connect-eamc/v1/client/create',
  ICP_METADATA_SAVE: '/am-portal-setup-svc/icp-metadata-admin/v1/idp/save',
  ORG_PREFERENCES_UPDATE: '/am-portal-setup-svc/preferences/v1/org-preferences/update',
  AAD_CONSENT_URL: '/am-portal-setup-svc/tenant-setup/v1/aad-consent-url/get',
  COMPLETE_SETUP: '/am-portal-setup-svc/tenant-setup/v1/complete',
  TENANT_LOOKUP_BY_DOMAIN: '/am-portal-svc/tenant-lookup/v1/tenants-by-domain/get',
  GET_CLIENT_USER_SESSION: '/am-portal-svc/session/v1/client-user-session/get',
  GET_LANDING_PAGE: '/am-portal-svc/v2/landing-page/get',
  PREPARE_FILE_UPLOAD: '/am-portal-setup-svc/file-access/v1/prepare-file-upload',
  LOGOUT: '/idp-web/idp/logout',
} as const;

export const CommonHeaders = {
  'Content-Type': 'application/json',
  'Strict-Transport-Security': 'max-age=86400;includeSubDomains',
  'X-XSS-Protection': '1;mode=block',
  'X-Content-Type-Options': 'nosniff',
  'X-Frame-Options': 'DENY',
};

export const IdpChoices = {
  ENTRA_ID_VALUE: 'entraId',
  EXTERNAL_VALUE: 'external',
  NONE_VALUE: 'none',
} as const;

export const StorageKeys = {
  WORKFLOW_ID: 'ImprWorkflowId',
  TENANT_SETUP_STATE: 'tenantSetupState', // type SetupState
  DPA_STATUS: 'DPA_STATUS', // type DpaStatus
  ORG_EMAIL: 'orgEmail',
  INTEGRATION_TOKEN_USED: 'INTEGRATION_TOKEN_USED', // type boolean
  EXT_IdP_USED: 'EXT_IdP_USED', // type boolean
};

export enum SetupState {
  STARTED = 'started',
  COMPLETED = 'completed',
}

export enum DpaStatus {
  GRANTED = 'GRANTED',
}
