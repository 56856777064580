import type { PropsWithChildren } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CUSTOMER_EXPERIENCE_CENTER_URL } from '../../constants';
import { useIdleTimeout } from '../../hooks/useIdleTimeout';
import LandingPageHeader from '../landing/LandingPageHeader';

export interface PortalLayoutProps {
  onLogout?: (tenantId: string) => void;
  tenantId?: string;
}

const PortalLayout = ({ tenantId, onLogout, children }: PropsWithChildren<PortalLayoutProps>) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleSettings = () => {
    console.log('Settings clicked: ');
    navigate({
      pathname: '/settings',
      search: `?${searchParams.toString()}`,
    });
  };

  const userLogout = async () => {
    console.debug('Logout');
    if (onLogout && tenantId) {
      onLogout(tenantId);
    }
  };

  const { startIdleTimer } = useIdleTimeout(30, userLogout);
  startIdleTimer();

  return (
    <div className="landing-page">
      <LandingPageHeader userLogout={userLogout} handleSettings={handleSettings} />
      <div className="page-header-container">
        <div className={'page-header'}>
          <a href={CUSTOMER_EXPERIENCE_CENTER_URL} target={'_blank'} rel={'noreferrer'}>
            CUSTOMER EXPERIENCE CENTER
          </a>
        </div>
      </div>
      {children}
    </div>
  );
};

export default PortalLayout;
